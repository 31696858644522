export default function Header({ children }) {
  return (
    <div className="container max-w-screen-lg mx-auto">
      <div className="container shadow px-10 py-10">
        <h1 className="text-3xl my-4">
          <span className="text-centric-green">cc</span>
          &nbsp;Reports
        </h1>
        <hr />
        {children}
      </div>
    </div>
  );
}
