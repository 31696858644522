import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import AuthContext from './context';
import Header from './Header';

export default function Auth({ children }) {
  const [accessToken, setAccessToken] = useState(
    localStorage.getItem('accessToken')
  );
  const [tokenSecret, setTokenSecret] = useState(
    localStorage.getItem('tokenSecret')
  );
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const accessToken = searchParams.get('access_token');
    const tokenSecret = searchParams.get('token_secret');
    if (accessToken && tokenSecret) {
      setAccessToken(accessToken);
      localStorage.setItem('accessToken', accessToken);

      setTokenSecret(tokenSecret);
      localStorage.setItem('tokenSecret', tokenSecret);

      navigate('/');
    }
  }, [searchParams, navigate]);

  return (
    <Header>
      {accessToken && tokenSecret ? (
        <AuthContext.Provider value={{ accessToken, tokenSecret }}>
          {children}
        </AuthContext.Provider>
      ) : (
        <div className="container py-10 space-y-4">
          Um die Funktionalität dieser Anwendung nutzen zu können, musst du dich
          erst bei JIRA authentifizieren:&nbsp;
          <a
            href={`${process.env.REACT_APP_BASE_URL}/oauth/requestToken`}
            target="_self"
            className="underline decoration-code-blue decoration-4"
          >
            Klick
          </a>
        </div>
      )}
    </Header>
  );
}
