import { useReducer } from 'react';
import reducer, {
  ANFANGSDATUM_CHANGED,
  ENDDATUM_CHANGED,
  INITIAL_STATE,
  ORGANISATION_CC_CLOUD,
  ORGANISATION_CHANGED,
  ORGANISATION_CODECENTRIC,
  REPORT_CHANGED,
  REPORT_RECHNUNGSSTELLER,
  REPORT_STUNDENZETTEL,
  VORGANGSSCHLUESSEL_CHANGED,
  CHECKBOX_UHRZEIT_CHANGED
} from './reducer';
import classNames from 'classnames';
import AuthContext from './context';

function generateDownloadLink(state, accessToken, tokenSecret) {
  return state.reportArt === REPORT_STUNDENZETTEL
    ? `${process.env.REACT_APP_BASE_URL}/timeSheet/${state.vorgangsschluessel}/${state.anfangsdatum}/${state.enddatum}?withTimestamps=${state.mitUhrzeit}&accessToken=${accessToken}&tokenSecret=${tokenSecret}`
    : `${process.env.REACT_APP_BASE_URL}/invoicing/${state.anfangsdatum}/${state.enddatum}/${state.organisation}?withTimestamps=${state.mitUhrzeit}&accessToken=${accessToken}&tokenSecret=${tokenSecret}`;
}

function App() {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  return (
    <AuthContext.Consumer>
      {({ accessToken, tokenSecret }) => (
        <div className="container py-10 space-y-4">
          <div className="container">
            <label htmlFor="reportArt" className="block font-semibold">
              Auswählen
            </label>
            <select
              id="reportArt"
              className="w-full rounded-md"
              value={state.reportArt}
              onChange={({ target: { value } }) =>
                dispatch({ type: REPORT_CHANGED, payload: value })
              }
            >
              <option value={REPORT_STUNDENZETTEL}>
                {REPORT_STUNDENZETTEL}
              </option>
              <option value={REPORT_RECHNUNGSSTELLER}>
                {REPORT_RECHNUNGSSTELLER}
              </option>
            </select>
          </div>
          {state.reportArt === REPORT_STUNDENZETTEL && (
            <div className="container">
              <label
                htmlFor="vorgangsschluessel"
                className="block font-semibold"
              >
                Vorgangsschlüssel
              </label>
              <input
                type="text"
                id="vorgangsschluessel"
                className="w-full rounded-md"
                value={state.vorgangsschluessel}
                onChange={({ target: { value } }) =>
                  dispatch({
                    type: VORGANGSSCHLUESSEL_CHANGED,
                    payload: value
                  })
                }
                required
              />
              {!state.vorgangsschluesselValide && (
                <span className="text-xs text-red-600">
                  Der Vorgangsschlüssel muss dem Format TS-1234 entsprechen.
                </span>
              )}
            </div>
          )}
          {state.reportArt === REPORT_RECHNUNGSSTELLER && (
            <div className="container">
              <label htmlFor="organisation" className="block font-semibold">
                Organisation
              </label>
              <select
                id="organisation"
                className="w-full"
                value={state.organisation}
                onChange={({ target: { value } }) =>
                  dispatch({ type: ORGANISATION_CHANGED, payload: value })
                }
              >
                <option value={ORGANISATION_CODECENTRIC}>
                  {ORGANISATION_CODECENTRIC}
                </option>
                <option value={ORGANISATION_CC_CLOUD}>
                  {ORGANISATION_CC_CLOUD}
                </option>
              </select>
            </div>
          )}
          <div className="container">
            <label htmlFor="anfangsdatum" className="block font-semibold">
              Anfangsdatum
            </label>
            <input
              id="anfangsdatum"
              type="date"
              className="w-full rounded-md"
              value={state.anfangsdatum}
              onChange={({ target: { value } }) =>
                dispatch({ type: ANFANGSDATUM_CHANGED, payload: value })
              }
            />
            {!state.anfangsdatumValide && (
              <span className="text-xs text-red-600">
                Das Anfangsdatum ist ungültig.
              </span>
            )}
          </div>
          <div className="container">
            <label htmlFor="enddatum" className="block font-semibold">
              Enddatum
            </label>
            <input
              id="enddatum"
              type="date"
              className="w-full rounded-md"
              value={state.enddatum}
              onChange={({ target: { value } }) =>
                dispatch({ type: ENDDATUM_CHANGED, payload: value })
              }
            />
            {!state.enddatumValide && (
              <span className="block text-xs text-red-600">
                Das Enddatum ist ungültig.
              </span>
            )}
            {!state.datumsauswahlValide && (
              <span className="text-xs text-red-600">
                Das Enddatum muss am selben Tag oder später als das Anfangsdatum
                liegen.
              </span>
            )}
          </div>
          <div>
            <label className="inline-flex items-center">
              <input
                type="checkbox"
                className="form-checkbox rounded"
                onChange={() =>
                  dispatch({
                    type: CHECKBOX_UHRZEIT_CHANGED,
                    payload: !state.mitUhrzeit
                  })
                }
              />
              <span className="ml-2">Uhrzeiten exportieren</span>
            </label>
          </div>
          <div className="flex flex-row justify-end">
            <a
              href={generateDownloadLink(state, accessToken, tokenSecret)}
              target="_blank"
              rel="noreferrer"
              className={classNames(
                'text-white',
                'font-bold',
                'py-2',
                'px-4',
                'rounded',
                { 'bg-gray-300': !state.download },
                {
                  'bg-centric-green': state.download
                }
              )}
              disabled={!state.download}
            >
              Download
            </a>
          </div>
        </div>
      )}
    </AuthContext.Consumer>
  );
}

export default App;
