import {
  endOfMonth,
  format,
  isAfter,
  isEqual,
  isValid,
  startOfMonth,
  subMonths
} from 'date-fns';

export const REPORT_STUNDENZETTEL = 'Stundenzettel';
export const REPORT_RECHNUNGSSTELLER = 'Rechnungssteller-Report';

export const ORGANISATION_CODECENTRIC = 'codecentric AG';
export const ORGANISATION_CC_CLOUD = 'cc cloud GmbH';

const CURRENT_DATE = new Date();
const DEFAULT_ANFANGSDATUM = startOfMonth(subMonths(CURRENT_DATE, 1));
const DEFAULT_ENDDATUM = endOfMonth(subMonths(CURRENT_DATE, 1));

export const INITIAL_STATE = {
  reportArt: REPORT_STUNDENZETTEL,
  vorgangsschluessel: '',
  vorgangsschluesselValide: true,
  organisation: ORGANISATION_CODECENTRIC,
  anfangsdatum: format(DEFAULT_ANFANGSDATUM, 'yyyy-MM-dd'),
  anfangsdatumValide: true,
  enddatum: format(DEFAULT_ENDDATUM, 'yyyy-MM-dd'),
  enddatumValide: true,
  datumsauswahlValide: true,
  download: false,
  mitUhrzeit: false
};

export const REPORT_CHANGED = 'REPORT_CHANGED';
export const VORGANGSSCHLUESSEL_CHANGED = 'VORGANGSSCHLUESSEL_CHANGED';
export const ORGANISATION_CHANGED = 'ORGANISATION_CHANGED';
export const ANFANGSDATUM_CHANGED = 'ANFANGSDATUM_CHANGED';
export const ENDDATUM_CHANGED = 'ENDDATUM_CHANGED';
export const CHECKBOX_UHRZEIT_CHANGED = 'CHECKBOX_UHRZEIT_CHANGED';

const VORGANGSSCHLUESSEL_REGEXP = new RegExp(/^(TS|ts)-[0-9]{1,}/);

const validiereDatumsauswahl = (anfangsdatum, enddatum) => {
  const anfang = new Date(anfangsdatum);
  const ende = new Date(enddatum);
  return isAfter(ende, anfang) || isEqual(ende, anfang);
};

export default function reducer(state, action) {
  switch (action.type) {
    case CHECKBOX_UHRZEIT_CHANGED:
      return {
        ...state,
        mitUhrzeit: action.payload
      };
    case REPORT_CHANGED:
      return {
        ...state,
        reportArt: action.payload
      };
    case VORGANGSSCHLUESSEL_CHANGED:
      const vorgangsschluessel = action.payload;
      const vorgangsschluesselValide =
        VORGANGSSCHLUESSEL_REGEXP.test(vorgangsschluessel);
      return {
        ...state,
        vorgangsschluessel,
        vorgangsschluesselValide,
        download:
          vorgangsschluesselValide &&
          state.anfangsdatumValide &&
          state.enddatumValide &&
          state.datumsauswahlValide
      };
    case ORGANISATION_CHANGED:
      return {
        ...state,
        organisation: action.payload
      };
    case ANFANGSDATUM_CHANGED:
      const anfangsdatum = action.payload;
      const anfangsdatumValide = isValid(new Date(anfangsdatum));
      return {
        ...state,
        anfangsdatum,
        anfangsdatumValide,
        download:
          state.vorgangsschluesselValide &&
          anfangsdatumValide &&
          state.enddatumValide &&
          state.datumsauswahlValide
      };
    case ENDDATUM_CHANGED:
      const enddatum = action.payload;
      const enddatumValide = isValid(new Date(enddatum));
      const datumsauswahlValide = validiereDatumsauswahl(
        state.anfangsdatum,
        enddatum
      );
      return {
        ...state,
        enddatum,
        enddatumValide,
        datumsauswahlValide,
        download:
          state.vorgangsschluesselValide &&
          state.anfangsdatumValide &&
          enddatumValide &&
          datumsauswahlValide
      };
    default:
      throw new Error('Unbekannte Aktion');
  }
}
